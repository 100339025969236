@import "bootstrap/scss/functions";

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../fonts" !default;

// flag icons location in relation to target location of the css file.
$flag-icons-path: "../fonts/flags" !default;

// Icon sizes
$icon-size: 35px;

// Colors
// Brand colors
$white: #fff;
$black: #000;
$slate: #393b42;
$yellow: #ffcf06;
$blue: #82b4bd;
// Gray
$gray: #9b9b9b;
// contrast against white; for 'disabled' text etc.
$gray-ada: #737373;
// Background tones
$slate-v1: #e1e2e3;
$slate-v2: #fafafa;
$blue-v1: #eef4f5;
$blue-v2: #f7fafb;
// Utility colors
$red: #d0021b;
$green: #498813;

// SFRA supporting colors below
$light-blue: #7ed0ee !default;
$dark-blue: #061c3f;
$black: #000 !default;
// Consolidated values
$grey1: #f9f9f9 !default;
$grey2: #eee !default;
$grey3: #ccc !default;
$grey4: #999 !default;
$grey5: #666 !default;
$grey6: #444 !default;
$grey7: #222 !default;
$grey8: #333 !default;
$grey-transparent-1: rgba(0, 0, 0, 0.65) !default;
$grey-transparent-2: rgba(0, 0, 0, 0.25) !default;
$gray-transparent-1: rgba(0, 0, 0, 0.4) !default;
$gray-1: #222 !default;
$gray-2: #444 !default;
$gray-3: #999 !default;
$gray-4: #ccc !default;
$light-gray: $grey1 !default;
$slightly-darker-gray: $grey4 !default;
$dark-gray: $grey6 !default;
$darker-gray: $grey7 !default;
$horizontal-rule-grey: $grey3 !default;
$product-number-grey: $grey3 !default;
$horizontal-border-grey: $grey4 !default;
$menu-link: $grey6 !default;
$close-menu-bg: $grey2 !default;
$link-color: $dark-gray !default;
$hr-border-color: $grey3 !default;
// END SFRA supporting colors

$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1600px,
    xxxxl: 1800px
) !default;

$container-max-widths: (
    md: 720px,
    lg: 940px,
    xl: 1140px
) !default;

$width-xl: 1248px;

$border-radius: 0.1875rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;

$state-danger-text: #fff !default;
$alert-success-text: #fff !default;

// Font sizes
$base-font-size: 14px !default;
$lg-font-size: 16px !default;
$sm-font-size: 12px !default;
$xs-font-size: 10px !default;

$heading-1-font-size: 72px;
$heading-2-font-size: 55px;
$heading-3-font-size: 30px;
$heading-4-font-size: 25px;
$heading-5-font-size: 20px;
$heading-6-font-size: 15px;
$heading-7-font-size: 12px;

// Font family
$univers: 'Univers LT W05_55 Roman', sans-serif;
$univers-bold: 'Univers LT W04_65 Bold1475972', sans-serif;
$trade-gothic-bold-condensed: 'TradeGothicW01-BoldCn20_675334', sans-serif;
$trade-gothic-bold: $trade-gothic-bold-condensed, sans-serif;

$brand-font: $trade-gothic-bold-condensed;
$brand-font-condensed: $trade-gothic-bold-condensed;
$body-font: $univers;
$body-font-bold: $univers-bold;
$base-font-family: $body-font !default;

// Font weight
$base-font-weight: 400 !default;
$font-weight-huskie: 600 !default;
$font-weight-heavy: 700 !default;
$font-weight-heavier: 900 !default;

// Font sizes
$base16-14px: 0.875em !default;

// Bootstrap overrides
$body-bg: $white !default;
$card-cap-bg: $white !default;

// Tabs
$nav-tabs-border-width: 0 !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-active-link-hover-bg: transparent !default;

// breadcrumb
$breadcrumb-bg: transparent !default;

// table border
$table-border-color: $slate-v1 !default;

$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;

// Spinner
$spinner-size: 80px;
$spinner-underlay-color: rgba(57, 59, 66, 0.66);

// Media orientations
$orientation-landscape: landscape;
$orientation-portrait: portrait;

// Aspect Ratios
$embed-responsive-aspect-ratios: (
    (185 124),
    (140 69),
    (21 9),
    (16 9),
    (4 3),
    (1 1)
) !default;
