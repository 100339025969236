@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "components/formFields";
@import "components/headers";

.account-page {
    max-width: 1240px;
    padding: 40px 20px 100px;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        padding: 10px 20px;
    }

    .col-12,
    .col-md-6 {
        padding: 0 10px;
    }
}

.account-page,
.cart-page,
.cart-wishlist {
    .card-container {
        width: 100%;
        padding: 60px;
        background-color: $white;
        margin: 10px 0;

        .card-head {
            @include h4();
        }

        .required-asterisk-legend {
            margin-bottom: 14px;
        }

        @include media-breakpoint-down(sm) {
            padding: 15px;

            .card-head {
                @include h4();
            }

            .account-button {
                width: 100%;
            }
        }

        input[type=password] {
            letter-spacing: 2px;
        }
    }

    .login {
        width: 100%;
    }

    #password-reset {
        text-decoration: underline;
    }

    .reset-password-modal,
    .login-modal {
        .heading {
            padding-top: 60px;
            @include h3();

            @include media-breakpoint-down(sm) {
                padding-top: 25px;
                @include h4();
            }
        }

        .required-asterisk-legend {
            margin-bottom: 14px;
        }

        .request-password-body {
            font-size: $base-font-size;
            font-family: $body-font;
        }
    }

    .oauth-section-divider {
        padding-top: 5px;
        margin: 10px 0;

        .left-line,
        .right-line {
            border-top: 1px solid $gray;
            margin-top: 9px;
        }
    }

    .oauth-options {
        h3 {
            @include h6();

            text-align: center;
        }
    }

    .login-oauth {
        .fa {
            padding-right: 5px;
        }

        .icon-leatherman {
            width: 1rem;
            height: 1rem;
            margin-bottom: -2px;
            margin-right: 5px;
        }
    }

    #track-order-form {
        .required-asterisk-legend {
            margin-bottom: 18px;
        }
    }
}
