.required-asterisk {
    position: relative;
    top: -5px;
}

.required-asterisk-legend {
    width: 100%;
    font-family: $body-font;
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    text-transform: none;
    margin: 0;
    padding-top: 7px;
}

.form-control-label {
    display: inline-block;
    font-size: 14px;
    line-height: 19px;
}

.invalid-feedback {
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.25s;
    display: block;
}

.form-control {
    border: 1px solid $slate;
    border-radius: 0;
    line-height: 1.7;
    height: 45px;
    background-color: $white;
    color: $slate;

    &::placeholder {
        color: $gray-ada !important;
    }

    &:focus {
        border-color: $gray-ada;
        box-shadow: 0 0 0 0.17rem $gray-ada;
    }

    &.is-invalid {
        color: $red;
        border: 1px solid $red;

        ~ .invalid-feedback {
            height: 1.5em;
            opacity: 1;
            visibility: visible;
        }
    }
}

.form-radio,
.form-recaptcha {
    &.is-invalid {
        .invalid-feedback {
            color: $red;
            height: 1.5em;
            opacity: 1;
            visibility: visible;
        }
    }
}

.input-group-prepend,
.input-group-append {
    .btn {
        height: 45px;
        border: 1px solid $slate;
        line-height: 15px;
    }
}

// TODO: Fix the forms.
.form-checkbox {
    margin-bottom: 10px;
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;

    input[type=checkbox] {
        margin-right: 7px;
        cursor: pointer;
        display: inline-block;
        height: initial;
        width: initial;

        /* stylelint-disable selector-pseudo-class-no-unknown */
        &:focus-visible {
            border-color: $slate;
            box-shadow: 0 0 0 2 0.17rem $slate;
        }
    }

    input {
        width: 15px;
        transform: translateY(1px);
    }

    label {
        margin-bottom: 0;
        width: calc(100% - 40px);
    }

    .invalid-feedback {
        margin: 0;
    }
}

.form-check:not(.correct) {
    padding-left: 0;
}

.form-check-input:not(.correct) {
    position: static;
    margin: 0;
}

.form-check-label:not(.correct) {
    vertical-align: text-bottom;
}

.form-radio {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.form-radio__option {
    display: flex;
    align-items: center;

    label {
        margin-right: 5px;
        margin-bottom: 6px;
    }

    div {
        margin-bottom: 8px;
    }
}

input[type=radio] {
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;

    // not sure why, having our own 'focus-visible' ruins it.
    // just radios, not checkboxes.
}

select {
    &,
    &.form-control,
    &.form-control.is-invalid {
        padding: 0 45px 0 15px;
        background: url("../images/ui/icon_arrow_down.svg") no-repeat center right 15px;
        background-color: $white;

        &:focus {
            outline: none;
        }

        &:focus-visible {
            outline: 2px solid $slate;
            outline-offset: 2px;
        }
    }
}

textarea {
    &,
    &.form-control {
        max-width: 100%;
        height: 180px;
        resize: none;
    }
}

// Quantity
.attribute.quantity,
.quantity-form {
    .quantity-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 45px;
        border: solid 1px $slate;
        border-radius: 0;

        span {
            height: 100%;
            display: flex;
            // for IE11
            flex: 1 0 auto;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            button {
                height: 10px;
                width: 10px;
                border: none;
                background-color: transparent;

                &:focus {
                    outline: none;
                    border: none;
                }

                &:focus-visible {
                    outline: 2px solid $slate;
                    outline-offset: 2px;
                }
            }
        }
    }

    input[type="number"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    .form-control.quantity-select {
        // for IE11
        height: 98%;
        // for IE11
        width: 30%;
        border: none;
        box-shadow: none;
        text-align: center;
        padding: 0;
    }

    .icon {
        width: 10px;
        height: 10px;
        color: $gray;
    }

    // Cart specific
    .quantity {
        min-width: auto;
    }
}

// Auto fill
input:-webkit-autofill,
select:-webkit-autofill {
    &,
    &:hover,
    &:focus {
        -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.01) inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}

select.product-registration-select {
    background: url("../../images/ui/icon_arrow_down.svg") no-repeat center right 15px !important;
}

input.form-control,
select.form-control,
textarea.form-control {
    font-size: 16px;

    &::placeholder {
        color: $gray-ada;
    }
}

input[type=date] {
    -webkit-appearance: none;
}
