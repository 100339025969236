@mixin h1() {
    font-size: $heading-1-font-size;
    font-family: $brand-font;
    font-weight: $base-font-weight;
    line-height: 1;
}

h1,
.h1 {
    @include h1();
}

@mixin h2() {
    font-size: $heading-2-font-size;
    font-family: $brand-font;
    font-weight: $base-font-weight;
    line-height: 1;
}

h2,
.h2 {
    @include h2();
}

@mixin h3() {
    font-size: $heading-3-font-size;
    font-family: $brand-font;
    font-weight: $base-font-weight;
    line-height: 1;
}

h3,
.h3 {
    @include h3();
}

@mixin h4() {
    font-size: $heading-4-font-size;
    font-family: $brand-font;
    font-weight: $base-font-weight;
    line-height: 1;
}

h4,
.h4 {
    @include h4;
}

@mixin h5() {
    font-size: $heading-5-font-size;
    font-family: $brand-font;
    font-weight: $base-font-weight;
    line-height: 1;
}

h5,
.h5 {
    @include h5();
}

@mixin h6() {
    font-size: $heading-6-font-size;
    font-family: $brand-font;
    font-weight: $base-font-weight;
    line-height: 1;
}

h6,
.h6 {
    @include h6();
}

@mixin h7() {
    font-size: $heading-7-font-size;
    font-family: $brand-font;
    font-weight: $base-font-weight;
    line-height: 1;
}

.h7 {
    @include h7();
}
